@import "src/styles/common";
@import "src/assets/fonts/stylesheet";
@import "src/styles/icons";
@import "src/styles/pages";
@import "src/styles/globals/inputs";
@import "src/styles/globals/select";
@import "src/styles/globals/dialog";
@import "src/styles/globals/quill";
@import "src/styles/globals/tippy";

* {
  font-family: Assistant, sans-serif;
  color: $text-color;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background: $light-gray;
}



// Standard Elements

img {
  max-width: 100%;
  height: auto;
}

input, textarea {
  @include styled-input();
}

input::placeholder, textarea::placeholder {
  color: $cloudy;
}

textarea::placeholder {
  padding-top: 16px;
}

.field-wrapper {
  label {
    @include styled-label();
  }
}


// Utility Classes
.page-container {
  margin: 0 auto;
  width: 100%;
  max-width: $breakpoint-sm;
  padding: 0 16px;

  @media(min-width: $breakpoint-sm) {
    max-width: $breakpoint-sm;
  }
  @media(min-width: $breakpoint-md) {
    max-width: $breakpoint-md;
  }
  @media(min-width: $breakpoint-lg) {
    max-width: $breakpoint-lg;
  }
}

.full-page-container {
  max-width: 100vw;
  min-height: 100vh;
}

.spacer {
  flex: 1 1 auto;
}

a.disabled {
  pointer-events: none;
  opacity: 0.6;
  cursor: not-allowed;
}

.text-button {
  @include text-button();
}

.link-button {
  @include link-button();
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}

sup {
  color: inherit;
}
